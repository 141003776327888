<template>
  <div id="servicesContainer">
    <div class="navbarServices"></div>
      <div class="servicesTextTop">
        <div class="overflow-service-page">
          <h1 v-if="CMS" :class="{scaleYAnim: playAnimText}">{{heading}}</h1>
        </div>
        <div class="overflow-service-page">
          <span v-if="CMS" :class="{scaleYAnim: playAnimText}">{{subheading1}}</span>
        </div>
        <div class="overflow-service-page">
          <span v-if="CMS" :class="{scaleYAnim: playAnimText}">{{subheading2}}</span>
        </div>
      </div>
      <div class="services">
        <div class="item" v-for="(item, index) in services" :key="index">
          <div class="anotherWrapper animated zoomIn delay-1s" v-show="playAnimText">
            <div class="imgWrapper" :style="{backgroundColor: servicesFromStore[index].backgroundColor}">
              <img :src="servicesFromStore[index].img">
               <div class="inside-service-text-wrapper">
                 <div class="inside-service-text">
                   <div class="inside-service-text-child">
                     <h3>{{item.title}}</h3>
                    <div class="subtitle">{{item.subtitle}}</div>
                   </div>
                 </div>
                 <div class="inside-service-text-main">{{item.contentSmall}}</div>
                 <div class="btn" @click="$emit('changeRoute', ({name: servicesFromStore[index].routeName}))">
                  <div></div>
                  <span>LEARN MORE</span>
                </div>
               </div>
            </div>
            <div class="text-service-wrapper">
              <div class="text-service">
                <h1 class="animated delay-1s zoomIn" v-show="playAnimText">{{item.title}}</h1>
                <small class="animated delay-1s zoomIn" v-show="playAnimText">{{item.subtitle}}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    <footerComp/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      playAnimText: false,
      CMS: null,
      services: [],
      heading: '',
      subheading1: '',
      subheading2: ''
    }
  },
  //VueMeta
  metaInfo: {
    title: 'Products & Services',
    meta: [
      {
        //vmid: 'description',
        name: 'description',
        content:
                'Clement Labs collaborates, designs, develops, builds and runs amazing websites as well as public and private Shopify, Android and iOS Apps. We specialize in eCommerce Data Migration, Shopify On-boarding, Shopify Retail POS integration, Mobile Apps as well as Marketing and eCommerce consulting and services.'
      }
    ]
  },

  computed: {
    servicesFromStore() {
      return this.$store.state.services
    }
  },

  watch: {
    CMS() {
      this.heading = this.CMS.services_heading[0].text
      this.subheading1 = this.CMS.services_subheading_1[0].text.replace(/\s+/g, ' ')
      this.subheading2 = this.CMS.services_subheading_2[0].text.replace(/\s+/g, ' ')

      this.CMS.body[0].items.forEach(item => {
        this.services.push({
          title: item.service_title[0].text.replace(/\s+/g, ' '),
          subtitle: item.service_subtitle[0].text.replace(/\s+/g, ' '),
          contentSmall: item.service_content_small[0].text.replace(/\s+/g, ' ')
        })
      })
    }
  },

  beforeCreate() {
    // CMS DEMONSTRATION
    this.$prismic.client.getSingle('services')
    .then(doc => {
      this.CMS = doc.data
    })
  },

  mounted() {
    document.body.style.height = "auto"
    window.scrollTo(0, 0)

    setTimeout(() => {
      this.playAnimText = true
    }, 1200)
  },

  destroyed() {
    document.body.style.height = "1000vh"
  }
}
</script>

<style lang="sass">

$color1: rgba(38, 70, 83, 1)
$color2: rgba(42, 157, 143, 1)
$color3: rgba(233, 196, 106, 1)
$color4: rgba(244, 162, 97, 1)
$color5: rgba(231, 111, 81, 1)

#servicesContainer
  background-color: $color5
  width: 100vw
  position: absolute
  left: 0
  top: 0
  .servicesTextTop
    margin: 14vw 13vw 0 13vw
    display: flex
    flex-direction: column
    align-items: flex-start
    .overflow-service-page
      overflow: hidden
      text-align: left
      margin-bottom: 15px
      &:nth-child(2), &:nth-child(3)
        width: 500px
        @media (max-width: 600px)
          width: 80vw
      h1
        font-size: 45px
        margin: 0
        @media (max-width: 600px)
          font-size: 25px
        @media (min-width: 600px) and (max-width: 1100px)
          font-size: 35px
        @media (orientation: landscape) and (max-width: 1320px)
          font-size: 30px
        transition: transform 1.2s cubic-bezier(.165,.84,.44,1) .2s
        transform: translateY(100%)
      span
        display: block
        font-size: 19px
        letter-spacing: 2px
        @media (max-width: 600px)
          font-size: 17px
        @media (min-width: 600px) and (max-width: 1100px)
          font-size: 24px
        @media (orientation: landscape) and (max-width: 1320px)
          font-size: 19px
        transition: transform 1.2s cubic-bezier(.165,.84,.44,1) 0.6s
        transform: translateY(100%)
  .navbarServices
    background-color:#E76F51
  .services
    width: auto
    height: auto
    margin: 7vw 15vw
    @media (max-width: 600px)
      flex-direction: column
      align-items: center
    @media (max-width: 600px)
      margin: 20vw 5vw
    @media (min-width: 600px) and (max-width: 1400px)
      margin: 15vw 5vw
    display: flex
    flex-wrap: wrap
    justify-content: center
    .item
      margin: 30px 0
      width: 20vw
      height: 20vw
      @media (max-width: 600px)
        margin: 10vw 0
        width: 80vw
        height: 80vw
      @media (min-width: 600px) and (max-width: 1100px)
        width: 60vw
        height: 60vw
      @media (max-width: 1400px)
        width: 45vw
        height: 45vw
      @media (orientation: landscape) and (max-width: 600px)
        width: 60vw
        height: 60vw
        margin: 50px 0
      &:hover
        .anotherWrapper
          .imgWrapper
            transform: scale(1.8) !important
            .inside-service-text-wrapper
              opacity: 1
              /*.inside-service-text-main*/
              .inside-service-text
                .inside-service-text-child
                  transform: translate(0)
            img
              transform: scale(0.2) translate(0, 245%)
        .text-service-wrapper
          transform: scaleY(0)
          .text-service
            transform: translate(0, -200%)
            opacity: 0
      /* Important for adding new services! */
      &:nth-child(3), &:nth-child(4)
        .anotherWrapper
          .imgWrapper
            transform: scale(1.1)
      &:nth-child(5)
        .anotherWrapper
          .imgWrapper
            .inside-service-text-wrapper
              .btn
                &:hover
                  span
                    /*color: #fff*/
                    color: #333
                /*box-shadow: inset 0px 0px 0px 2px #333*/
                box-shadow: inset 0px 0px 0px 2px #fff
                @media (max-width: 600px)
                  /*box-shadow: inset 0px 0px 0px 1px #333*/
                box-shadow: inset 0px 0px 0px 1px #fff
                div
                  /*background-color: #333*/
                  background-color: #fff
            *
              //color: #333
              color: #fff
      /* */
      .anotherWrapper
        width: 60%
        height: 60%
        @media (min-width: 1700px)
          width: 50%
          height: 50%
        margin: 0 auto
        border-radius: 50%
        .imgWrapper
          border-radius: 50%
          transition: all 0.65s ease
          position: relative
          .inside-service-text-wrapper
              position: absolute
              display: flex
              flex-direction: column
              justify-content: center
              align-items: center
              opacity: 0
              transition: all 0.75s ease-in-out 0.35s
              left: 0
              right: 0
              top: 0
              bottom: 0
              margin: auto
              height: 100%
              width: 80%
              z-index: 10
              .btn
                bottom: 0
                margin: 0
                width: 90px
                height: 31px
                font-size: 10px
                @media (max-width: 600px)
                  height: 25px
                  width: 50%
                  box-shadow: inset 0px 0px 0px 1px #fff
                  span
                    font-size: 8px
                div
                  background-color: #fff
                &:hover
                  span
                    color: #333
              .inside-service-text-main
                font-size: 8px
                margin: 5% 0
                transition: all 0.65s cubic-bezier(1,.005,.37,1)
                @media (max-width: 600px)
                  font-size: 9px
                @media (min-width: 600px) and (max-width: 1100px)
                  font-size: 11px
                @media (min-width: 1100px) and (max-width: 1400px)
                  font-size: 16px
              .inside-service-text
                width: auto
                height: auto
                overflow: hidden
                .inside-service-text-child
                  transform: translate(0, 100%)
                  transition: all 0.6s ease 0.25s
                  h3
                    font-size: 15px
                    width: 90%
                    margin: 0 auto -2% auto
                    @media (max-width: 600px)
                      font-size: 12px
                    @media (min-width: 600px) and (max-width: 1100px)
                      font-size: 15px
                      margin-bottom: 5px
                    @media (min-width: 1100px) and (max-width: 1400px)
                      font-size: 27px
                      margin-bottom: 5px
                  .subtitle
                    margin-top: 3%
                    line-height: default
                    font-size: 9px
                    @media (max-width: 600px)
                      font-size: 8px
                    @media (min-width: 600px) and (max-width: 1100px)
                      font-size: 12px
                    @media (min-width: 1100px) and (max-width: 1400px)
                      font-size: 20px
          img
            width: 100%
            height: 100%
            transform: scale(1.1)
            transition: all 0.65s cubic-bezier(1,.005,.37,1)
            position: relative
            z-index: 100
      .text-service-wrapper
        width: 150%
        position: relative
        right: 25%
        height: auto
        transition: all 0.65s ease
        .text-service
          transition: all 0.65s ease
          h1
            margin: 10% 0 0 0
            font-size: 25px
            @media (max-width: 1600px) and (min-width: 1100px)
              font-size: 20px
            @media (min-width: 600px) and (max-width: 1100px)
              font-size: 27px
            @media (max-width: 600px)
              font-size: 22px
          small
            @media (min-width: 600px) and (max-width: 1100px)
              font-size: 20px
</style>
